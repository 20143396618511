<template>
    <div class="error">
        <h4>404 Not Found</h4>
    </div>
</template>

<script>
    export default {
        name: "Error"
    }
</script>

<style scoped>
    .error h4 {
        text-align: center;
    }
</style>
